<template>
	<v-card class="rounded-xl pa-6 text-center login-page" elevation="0" outlined>
		<h1>Wachtwoord wijzigen</h1>
		<v-form v-model="valid">
      <v-text-field
				solo
				background-color="#f1f8fa"
        :rules="rules.passwd"
				v-model="data.passwd"
				:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
				:type="show ? 'text' : 'password'"
				label="Wachtwoord"
				class="theme-input"
				@click:append="show = !show"
				validate-on-blur
			></v-text-field>
			<v-text-field
				solo
				background-color="#f1f8fa"
				:rules="rules.rpasswd"
				v-model="data.rpasswd"
				:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
				:type="show ? 'text' : 'password'"
				label="Wachtwoord"
				class="theme-input"
				@click:append="show = !show"
				validate-on-blur
			></v-text-field>
		</v-form>
		<v-btn class="text-white btn" color="themepurple" :loading="loading" @click="reset()"> Wijzig wachtwoord</v-btn>
	</v-card>
</template>

<script>
import api from "../../services/api";
import config from "../../config";
export default {
	methods: {
		reset() {
			if (this.valid && this.data.passwd === this.data.rpasswd) {
				this.data.token = location.search.substr(1);
				this.loading = true;
				api.post(`${config.url.api}/v1/auth/reset`, this.data)
					.then((res) => {
						if (res.data.s === 7) {
							this.$toast.success("Wachtwoord succesvol gereset!");
							this.success = true;
						} else {
							this.$toast.error("Reset token is niet meer geldig.");
						}
						this.loading = false;
					})
					.catch((err) => {
						switch (err.response.status) {
							default:
								this.$toast.error("Reset token is niet meer geldig.");
								break;
						}
						this.loading = false;
					});
			}
		},
	},
	data() {
		return {
			success: false,
			valid: false,
			show2: false,
			show1: false,
			s: 0,
			data: {
				passwd: "",
				rpasswd: "",
				token: "",
			},
			rules: {
				passwd: [
					(v) => !!v || "Required",
					(v) => (v && v.length >= 2) || "Minimum 8 characters",
					(v) => (v && v.length <= 64) || "Maximum 64 characters",
					(v) => config.regex.password.test(v) || "Invalid characters",
				],
				rpasswd: [
					(v) => !!v || "Required",
					(v) => (v && v.length >= 2) || "Minimum 8 characters",
					(v) => (v && v.length <= 64) || "Maximum 64 characters",
					(v) => (v && this.data.passwd == v) || "Passwords don't match",
					(v) => config.regex.password.test(v) || "Invalid characters",
				],
			},
		};
	},
};
</script>
